import React from "react";
import "./OurProjects.css";
import projectNashtaImg from "../../../images/project-nashta.jpeg";
import projectKULImg from "../../../images/Project-KUL.jpeg";
import plantKindnessImg from "../../../images/plant-kindness.jpeg";
import plantKindnessImg2 from "../../../images/plant-kindness2.jpeg";

import { Link } from "react-router-dom";


const OurProjects = () => {
  return (
    <section class="our-projects">
      <div >
        <h1 class="our-projects-heading"> Our Projects </h1>{" "}
      </div>{" "}
      <div class="row project-cards-row">
      <div class="row">

      <div class="col-sm-4">
      <div class="card project-card">
        <img class="card-img-top img-fluid" src={projectKULImg} alt="projectNashta"/>
        <div class="card-block p-3">
        <h4 class="card-title our-projects-heading">Project KUL</h4>
        <p class="card-text project-name-body">The project began at two Mumbai colleges, Sophias and St. Andrews, where we have offered a planned 30 hours of volunteering followed by deep reflection sessions. We plan to have the research published by June 2025 and scale to other colleges.</p>
        <div class="card-text row project-name-btns">
                  <div class="col read-more-btn">
                  <Link to="/project-kul">
                    <button type="button" class="btn btn-outline-dark">
                      {" "}
                      Read More{" "}
                    </button>{" "}
                    </Link>
                  </div>{" "}
                  <div class="col donate-btn">
                  <Link to="/donate">
                  <button type="button" class="btn btn-outline-dark">
                    Donate
                  </button>
                  </Link>
                  </div>{" "}
                </div>{" "}    </div>
    </div>
</div>
    <div class="col-sm-4">
    <div class="card project-card">
      <img class="card-img-top img-fluid" src={plantKindnessImg} alt="plantkindness"/>
      <img class="card-img-top img-fluid" src={plantKindnessImg2} alt="plantkindness"/>

      <div class="card-block p-2">
        <h4 class="card-title our-projects-heading">Plant Kindness</h4>
        <p class="card-text project-name-body">On our 75th Independence Day we commit to Plant Kindness and Be Kind to the Environment</p>
        <div class="card-text row project-name-btns">
                  <div class="col read-more-btn">
                    <Link to="/engagements/nutrition/plantkindness">
                    <button type="button" class="btn btn-outline-dark">
                      {" "}
                      Read More{" "}
                    </button>{" "}
                    </Link>
                  </div>{" "}
                  <div class="col donate-btn">
                  <Link to="/donate">
                  <button type="button" class="btn btn-outline-dark">
                    Donate
                  </button>
                  </Link>
                  </div>{" "}
                </div>{" "}
    </div>
    </div>
</div>

<div class="col-sm-4">
    <div class="card project-card">
      <img class="card-img-top img-fluid" src={projectNashtaImg} alt="projectNashta"/>
      <div class="card-block p-3">
        <h4 class="card-title our-projects-heading">Project Nashta</h4>
        <p class="card-text project-name-body">Kindness Unlimited has launched Project Nashta in April 2021 to address the nutrition needs of underprivileged children from Adivasi areas as well as the urban poor.

</p>
<div class="card-text row project-name-btns">
                  <div class="col read-more-btn">
                  <Link to="/engagements/nutrition/nashta">
                    <button type="button" class="btn btn-outline-dark">
                      {" "}
                      Read More{" "}
                    </button>{" "}
                    </Link>
                  </div>{" "}
                  <div class="col donate-btn">
                  <Link to="/donate">
                  <button type="button" class="btn btn-outline-dark">
                    Donate
                  </button>
                  </Link>
                  </div>{" "}
                </div>{" "}    </div>
    </div>
</div>

</div>
      </div>{" "}
    </section>
  );
};

export default OurProjects;
